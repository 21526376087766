<template>
  <CRow>
    <CCol md="12">
      <CCard border-color="primary">
        <CCardHeader @click="cardCollapse = !cardCollapse">
          <div class="card-header-actions text-right">
            <b class="mx-2 product_name">{{ ppl.price }} CHF</b> /
            {{ ppl.pw ? ppl.price_len + " Kg" + ppl.price_unit : "pezzo" }}
            <span class="collapser"><CIcon :name="collapsableIcon" /></span>
          </div>
          <div style="display: flex; flex-direction: row">
            <div class="mr-4 mt-2">
              <CSwitch
                v-if="!active_loading"
                class="mx-1"
                size="sm"
                color="success"
                data-on="On"
                data-off="Off"
                :checked.sync="ppl.is_active"
              />
              <CSpinner v-else color="success" size="sm" />
            </div>
            <div>
              <b class="product_name">{{
                ppl.translations[$i18n.locale].name
              }}</b>
              <br />
              PPL{{ ppl.id }}
            </div>
          </div>
        </CCardHeader>
        <CCardBody v-if="cardCollapse">
          <CRow v-if="ppl">
            <CCol md="12">
              <languageEditSelector
                class="float-right mx-1"
                v-model="cur_lang"
              />
            </CCol>
            <CCol md="2">
              <!-- <ImageCropper :config="img_cropper" @img_updated="UpdatePPdata" /> -->
              <ProductPPLimg v-model="ppl" :cur_lang="cur_lang" />
            </CCol>
            <CCol md="5">
              <!-- <ProductLotDataGen :ppl="ppl" /> -->
              <ProductPPLdata v-model="ppl" :cur_lang="cur_lang" />
            </CCol>
            <CCol md="5">
              <!-- <ProductLotDataPrice :ppl="ppl" /> -->
              <ProductPPLprice v-model="ppl" :cur_lang="cur_lang" />
            </CCol>
          </CRow>
          <div v-else class="loading">
            <CSpinner color="secondary" size="lg" />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { GetDataManager } from "../../../ds/index";
// import ImageCropper from "../../../common/cropper/ImageCropper";
// import { Query } from "@syncfusion/ej2-data";
// import ProductLotDataGen from "./ProductLotDataGen";
// import ProductLotDataPrice from "./ProductLotDataPrice";
// import ProductLotDataAval from "./ProductLotDataAval";
// import ProductLotDataOther from "./ProductLotDataOther";
import languageEditSelector from "../../../common/languageEditSelector";
import ProductPPLimg from "./ProductPPLimg";
import ProductPPLdata from "./ProductPPLdata";
import ProductPPLprice from "./ProductPPLprice";

export default {
  model: {
    prop: "bppl",
    event: "change",
  },
  props: ["bppl"],
  data() {
    return {
      ppl: this.bppl,
      cardCollapse: false,
      active_loading: false,
      cur_lang: this.$i18n.locale,
    };
  },
  watch: {
    bppl: function () {
      this.ppl = this.bppl;
    },
    "ppl.is_active": function () {
      // console.log("active", Nv, Ov)
      this.active_loading = true;
      let params = {
        id: this.ppl.id,
        is_active: this.ppl.is_active,
      };
      GetDataManager("role_producer_products_lots", [this.$store.state.role.id])
        .update("", params)
        .then(
          (e) => {
            this.$emit("change", e);

            this.active_loading = false;

            this.$store.dispatch("toaster/add", {
              title: this.$t("role.product.lots.toast.state.title"),
              text: this.$t("role.product.lots.toast.state.text"),
              autohide: true,
            });
          },
          () => {
            this.ppl.is_active = !this.ppl.is_active;
            this.active_loading = false;
            // this.dataForm.errors = errors;
            // this.dataForm.loading = false;
          }
        );
    },
  },
  computed: {
    collapsableIcon() {
      if (!this.cardCollapse) {
        return "cil-chevron-right";
      } else {
        return "cil-chevron-bottom";
      }
    },
    // img_cropper() {
    //   return {
    //     circle: false,
    //     title: this.$t("role.products.producer.detail.image.title"),
    //     obj_id: this.ppl.id,
    //     image_cropped: this.ppl.image_cropped,
    //     image: this.ppl.image,
    //     cropping: this.ppl.image_cropping,
    //     ratio: 800 / 700,
    //     collapsed: true,
    //     dm: GetDataManager("role_producer_products_lots", [
    //       this.$store.state.role.id,
    //     ]),
    //   };
    // },
  },
  components: {
    // ProductLotDataGen,
    // ProductLotDataPrice,
    // ProductLotDataAval,
    // ProductLotDataOther,
    // ImageCropper,
    ProductPPLimg,
    ProductPPLdata,
    ProductPPLprice,
    languageEditSelector,
  },
};
</script>

<style scoped>
.card-body div.loading {
  margin: auto;
}
.product_name {
  font-size: 1.5em;
}
.collapser {
  padding-bottom: 20px !important;
}
</style>
