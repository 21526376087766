<template>
  <CCard>
    <CCardHeader v-if="pp">
      {{ $t("role.product.lots.title") }}
      <b>{{ pp.translations[$i18n.locale].name }}</b>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CButtonToolbar
          aria-label="Toolbar with button groups"
          class="col-md-12"
        >
          <CButtonGroup class="mx-1">
            <div>
              <CButton color="secondary" @click="AddModal = true">
                {{ $t("role.product.lots.btn_add") }}</CButton
              >
            </div>
          </CButtonGroup>
          <CButtonGroup class="mx-auto">
            <CInput
              horizontal
              v-model="ppls_config.search"
              :placeholder="$t('role.product.lots.search_placeholder')"
            >
              <template #append-content
                ><CIcon name="cil-magnifying-glass" />
              </template>
            </CInput>
          </CButtonGroup>
          <CButtonGroup class="mx-1">
            <CDropdown
              right
              :togglerText="ActiveTogglerLabel"
              color="secondary"
            >
              <CDropdownItem @click="ppls_config.active = true">{{
                $t("role.product.lots.filter.state.active")
              }}</CDropdownItem>
              <CDropdownItem @click="ppls_config.active = false">{{
                $t("role.product.lots.filter.state.inactive")
              }}</CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem @click="ppls_config.active = null">{{
                $t("common.all")
              }}</CDropdownItem>
            </CDropdown>
          </CButtonGroup>
        </CButtonToolbar>
      </CRow>
      <CRow v-if="ppls_config.ppls">
        <CCol md="12" v-for="(ppl, index) in ppls_config.ppls" :key="ppl.id">
          <ProductPPL v-model="ppls_config.ppls[index]" />
        </CCol>
      </CRow>
      <div v-else class="loading"><CSpinner color="secondary" size="lg" /></div>
    </CCardBody>
    <ProductPPLSnew v-if="pp" v-model="AddModal" :pp="pp" />
  </CCard>
</template>

<script>
import ProductPPL from "./ProductPPL";
import ProductPPLSnew from "./ProductPPLSnew";

export default {
  model: {
    prop: "ppls_config",
    event: "change",
  },
  components: {
    ProductPPL,
    ProductPPLSnew,
  },
  props: ["pp", "ppls_config"],
  data() {
    return {
      AddModal: false,
    };
  },
  watch: {
    AddModal: function (Nv) {
      if (Nv == false) {
        this.$emit("UpdateLots");
      }
    },
  },
  computed: {
    ActiveTogglerLabel() {
      if (this.ppls_config.active == true) {
        return (
          this.$t("role.product.lots.filter.state.label") +
          ": " +
          this.$t("role.product.lots.filter.state.active")
        );
      } else if (this.ppls_config.active == false) {
        return (
          this.$t("role.product.lots.filter.state.label") +
          ": " +
          this.$t("role.product.lots.filter.state.inactive")
        );
      }
      return (
        this.$t("role.product.lots.filter.state.label") +
        ": " +
        this.$t("common.all")
      );
    },
  },
};
</script>
