<template>
  <FormCard v-if="ppl" :config="imgs_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";

export default {
  model: {
    prop: "ppl",
    event: "change",
  },
  props: ["ppl", "cur_lang"],
  components: {
    FormCard,
  },

  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      this.$emit("change", data);
    },
  },
  computed: {
    imgs_data() {
      return {
        title: this.$t("role.product.lots.card_image.title"),
        data: this.ppl,
        fields: {
          base_img: {
            title: this.$t("models.lot.image"),
            description: this.$t("models.lot.image_info"),
            type: "Cropper",
            size: 12,
            subsize: [12, 12],
            ratio: 800 / 700,
            image: "image",
            image_cropping: "image_cropping",
            image_cropped: "image_cropped",
            circle: false,
          },
        },
        dm: GetDataManager("role_producer_products_lots", [
          this.$store.state.role.id,
        ]),
      };
    },
  },
};
</script>
